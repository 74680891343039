// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$Notification from "../../../../bindings/Notification.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as ReactNotifications from "react-notifications";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";
import * as CreateColocationRfpProjectConfiguration_BasicInfo from "./CreateColocationRfpProjectConfiguration_BasicInfo.res.js";
import * as CreateColocationRfpProjectConfiguration_CrossConnect from "./CreateColocationRfpProjectConfiguration_CrossConnect.res.js";
import * as CreateColocationRfpProjectConfiguration_AdditionalNotes from "./CreateColocationRfpProjectConfiguration_AdditionalNotes.res.js";
import * as CreateColocationRfpProjectConfiguration_CloudDirectConnect from "./CreateColocationRfpProjectConfiguration_CloudDirectConnect.res.js";
import * as CreateColocationRfpProjectConfiguration_DatecenterFeatures from "./CreateColocationRfpProjectConfiguration_DatecenterFeatures.res.js";
import * as CreateColocationRfpProjectConfiguration_InternetConnectivity from "./CreateColocationRfpProjectConfiguration_InternetConnectivity.res.js";
import * as CreateColocationRfpProjectConfiguration_CertificationRequirements from "./CreateColocationRfpProjectConfiguration_CertificationRequirements.res.js";
import * as CreateColocationRfpProjectConfiguration_RackTypesAndPowerDensities from "./CreateColocationRfpProjectConfiguration_RackTypesAndPowerDensities.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration from "./CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.res.js";

var css = CreateColocationRfpProjectConfigurationScss;

var $$default = {
  colocationConfiguration: "Shown",
  internetConnectivity: "Hidden",
  crossConnect: "Hidden",
  cloudDirectConnect: "Hidden",
  certificationRequirements: "Hidden",
  datacenterFeatures: "Hidden",
  additionalNotes: "Hidden"
};

var VisibilityState = {
  $$default: $$default
};

function saveDraft(input) {
  $$Promise.wait(Api.saveColocationRfpProjectConfigurationDraft(input), (function (x) {
          if (x.TAG === "Ok") {
            return $$Notification.NotificationManager.success("Draft saved!", "", 1000);
          }
          SentryLogger.error1({
                rootModule: "CreateColocationRfpProjectConfiguration",
                subModulePath: /* [] */0,
                value: "saveDraft",
                fullPath: "CreateColocationRfpProjectConfiguration.saveDraft"
              }, "SaveProjectConfigurationDraft::Error", [
                "Error",
                x._0
              ]);
          $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
        }));
}

var saveDraft$1 = Debounce.make(1000, (function (param) {
        if (param[0] !== undefined) {
          return ;
        } else {
          return saveDraft(param[1]);
        }
      }));

var Handlers = {
  saveDraft: saveDraft$1
};

function CreateColocationRfpProjectConfiguration(props) {
  var projectStatus = props.projectStatus;
  var mobile = props.mobile;
  var projectId = props.projectId;
  var options = props.options;
  var colocationConfigurations = props.colocationConfigurations;
  var match = React.useState(function () {
        var match = colocationConfigurations.length;
        if (match !== 0) {
          return colocationConfigurations;
        } else {
          return [CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.empty()];
        }
      });
  var setConfigurations = match[1];
  var configurations = match[0];
  var match$1 = React.useState(function () {
        return Belt_Array.map(configurations, (function (param) {
                      return $$default;
                    }));
      });
  var setConfigurationVisibilities = match$1[1];
  var configurationVisibilities = match$1[0];
  var match$2 = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match$2[1];
  var signInModal = match$2[0];
  var saveColocationConfigurations = function (userAction, dispatch) {
    $$Promise.wait(Api.createColocationRfpProjectConfiguration(configurations, projectId), (function (x) {
            if (x.TAG === "Ok") {
              if (projectId !== undefined) {
                if (userAction === "ClickOnSaveAndContinueLater") {
                  return Url.visit(Routes_Project.index);
                } else {
                  return Url.visit(Routes_Project.Colocation.$$Location.update(Caml_option.valFromOption(projectId)));
                }
              } else {
                return Url.visit(Routes_Project.Colocation.$$Location.select);
              }
            }
            SentryLogger.error1({
                  rootModule: "CreateColocationRfpProjectConfiguration",
                  subModulePath: /* [] */0,
                  value: "make",
                  fullPath: "CreateColocationRfpProjectConfiguration.make"
                }, "Save::Error", [
                  "Error",
                  x._0
                ]);
            dispatch({
                  TAG: "SetErrorMessage",
                  _0: "Validation failed, please verify"
                });
          }));
  };
  var initialState_validationResults = [];
  var initialState_userLoginStatus = props.userLoginStatus;
  var initialState = {
    status: "Editing",
    validationResults: initialState_validationResults,
    submissionError: false,
    userLoginStatus: initialState_userLoginStatus,
    errorMessage: undefined
  };
  var match$3 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            validationResults: Belt_Array.map(configurations, CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Validate.all),
                            submissionError: state.submissionError,
                            userLoginStatus: state.userLoginStatus,
                            errorMessage: state.errorMessage
                          }
                        };
              case "FailSubmission" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Editing",
                            validationResults: state.validationResults,
                            submissionError: true,
                            userLoginStatus: state.userLoginStatus,
                            errorMessage: state.errorMessage
                          }
                        };
              case "SaveDraftProjectAndContinueLater" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            validationResults: Belt_Array.map(configurations, (function (configuration) {
                                    return {
                                            startDate: CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Validate.startDate(configuration),
                                            contractLength: {
                                              TAG: "Ok",
                                              _0: undefined
                                            },
                                            rackTypesAndPowerDensities: [],
                                            crossConnects: []
                                          };
                                  })),
                            submissionError: state.submissionError,
                            userLoginStatus: state.userLoginStatus,
                            errorMessage: state.errorMessage
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              if (Belt_Array.every(param.state.validationResults, CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Validate.valid)) {
                                if (projectId !== undefined) {
                                  return saveColocationConfigurations("ClickOnSaveAndContinueLater", dispatch);
                                } else {
                                  return $$Promise.wait(Api.saveColocationDraftProjectAndContinueLater("Colocation", {
                                                  TAG: "Colocation",
                                                  _0: "Rfp"
                                                }, "Draft"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return Url.visit(Routes_Project.index);
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "CreateColocationRfpProjectConfiguration",
                                                      subModulePath: /* [] */0,
                                                      value: "make",
                                                      fullPath: "CreateColocationRfpProjectConfiguration.make"
                                                    }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "SetErrorMessage",
                                                      _0: "Validation failed, please verify"
                                                    });
                                              }));
                                }
                              }
                              
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "Submit" :
                  var userAction = action._0;
                  var match = state.status;
                  if (match === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              validationResults: Belt_Array.map(configurations, CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Validate.all),
                              submissionError: state.submissionError,
                              userLoginStatus: state.userLoginStatus,
                              errorMessage: state.errorMessage
                            },
                            _1: (function (param) {
                                if (Belt_Array.every(param.state.validationResults, CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Validate.valid)) {
                                  return saveColocationConfigurations(userAction, param.dispatch);
                                }
                                
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "ToggleLoginStatus" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            validationResults: state.validationResults,
                            submissionError: state.submissionError,
                            userLoginStatus: action._0,
                            errorMessage: state.errorMessage
                          },
                          _1: (function (param) {
                              param.dispatch("SaveDraftProjectAndContinueLater");
                            })
                        };
              case "SetErrorMessage" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            validationResults: state.validationResults,
                            submissionError: state.submissionError,
                            userLoginStatus: state.userLoginStatus,
                            errorMessage: action._0
                          }
                        };
              
            }
          }
        }));
  var dispatch = match$3[1];
  var state = match$3[0];
  var generateId = function (fieldName, mainIndex, subType, subIndex, param) {
    return "colocation-configuration-" + (String(mainIndex) + ((
                subType !== undefined ? "-" + subType : ""
              ) + ((
                  subIndex !== undefined ? "-" + String(subIndex) : ""
                ) + ("--" + fieldName))));
  };
  var updateConfiguration = function (configuration, index) {
    var newConfigurations = Belt_Array.mapWithIndex(configurations, (function (idx, oldConfiguration) {
            if (idx === index) {
              return configuration;
            } else {
              return oldConfiguration;
            }
          }));
    setConfigurations(function (param) {
          return newConfigurations;
        });
    saveDraft$1([
          projectId,
          newConfigurations
        ]);
  };
  var toggleVisibility = function (index, section) {
    var visibilities = Belt_Array.mapWithIndex(configurationVisibilities, (function (idx, visibility) {
            if (index === idx) {
              if (section === "DatacenterFeatures") {
                return {
                        colocationConfiguration: visibility.colocationConfiguration,
                        internetConnectivity: visibility.internetConnectivity,
                        crossConnect: visibility.crossConnect,
                        cloudDirectConnect: visibility.cloudDirectConnect,
                        certificationRequirements: visibility.certificationRequirements,
                        datacenterFeatures: Visibility.toggle(visibility.datacenterFeatures),
                        additionalNotes: visibility.additionalNotes
                      };
              } else if (section === "AdditionalNotes") {
                return {
                        colocationConfiguration: visibility.colocationConfiguration,
                        internetConnectivity: visibility.internetConnectivity,
                        crossConnect: visibility.crossConnect,
                        cloudDirectConnect: visibility.cloudDirectConnect,
                        certificationRequirements: visibility.certificationRequirements,
                        datacenterFeatures: visibility.datacenterFeatures,
                        additionalNotes: Visibility.toggle(visibility.additionalNotes)
                      };
              } else if (section === "InternetConnectivity") {
                return {
                        colocationConfiguration: visibility.colocationConfiguration,
                        internetConnectivity: Visibility.toggle(visibility.internetConnectivity),
                        crossConnect: visibility.crossConnect,
                        cloudDirectConnect: visibility.cloudDirectConnect,
                        certificationRequirements: visibility.certificationRequirements,
                        datacenterFeatures: visibility.datacenterFeatures,
                        additionalNotes: visibility.additionalNotes
                      };
              } else if (section === "CertificationRequirements") {
                return {
                        colocationConfiguration: visibility.colocationConfiguration,
                        internetConnectivity: visibility.internetConnectivity,
                        crossConnect: visibility.crossConnect,
                        cloudDirectConnect: visibility.cloudDirectConnect,
                        certificationRequirements: Visibility.toggle(visibility.certificationRequirements),
                        datacenterFeatures: visibility.datacenterFeatures,
                        additionalNotes: visibility.additionalNotes
                      };
              } else if (section === "CloudDirectConnect") {
                return {
                        colocationConfiguration: visibility.colocationConfiguration,
                        internetConnectivity: visibility.internetConnectivity,
                        crossConnect: visibility.crossConnect,
                        cloudDirectConnect: Visibility.toggle(visibility.cloudDirectConnect),
                        certificationRequirements: visibility.certificationRequirements,
                        datacenterFeatures: visibility.datacenterFeatures,
                        additionalNotes: visibility.additionalNotes
                      };
              } else if (section === "ColocationConfiguration") {
                return {
                        colocationConfiguration: Visibility.toggle(visibility.colocationConfiguration),
                        internetConnectivity: visibility.internetConnectivity,
                        crossConnect: visibility.crossConnect,
                        cloudDirectConnect: visibility.cloudDirectConnect,
                        certificationRequirements: visibility.certificationRequirements,
                        datacenterFeatures: visibility.datacenterFeatures,
                        additionalNotes: visibility.additionalNotes
                      };
              } else {
                return {
                        colocationConfiguration: visibility.colocationConfiguration,
                        internetConnectivity: visibility.internetConnectivity,
                        crossConnect: Visibility.toggle(visibility.crossConnect),
                        cloudDirectConnect: visibility.cloudDirectConnect,
                        certificationRequirements: visibility.certificationRequirements,
                        datacenterFeatures: visibility.datacenterFeatures,
                        additionalNotes: visibility.additionalNotes
                      };
              }
            } else {
              return visibility;
            }
          }));
    setConfigurationVisibilities(function (param) {
          return visibilities;
        });
  };
  var cancelCreation = function () {
    $$Promise.wait(Api.cancelProjectCreation(), (function (x) {
            if (x.TAG === "Ok") {
              return Url.visit(Routes_Project.index);
            } else {
              return SentryLogger.error1({
                          rootModule: "CreateColocationRfpProjectConfiguration",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "CreateColocationRfpProjectConfiguration.make"
                        }, "CancelProjectCreation::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var navigationPreviousControl_1 = function (param) {
    cancelCreation();
  };
  var navigationPreviousControl = [
    "Cancel",
    navigationPreviousControl_1,
    "Enabled"
  ];
  var navigationNextControl = React.useMemo((function () {
          return [
                  projectId !== undefined ? "Save and Next" : "Next",
                  (function () {
                      var match = state.userLoginStatus;
                      if (match === "LoggedIn") {
                        return dispatch({
                                    TAG: "Submit",
                                    _0: "ClickOnNext"
                                  });
                      } else {
                        return toggleSignInModal(function (param) {
                                    return Visibility.toggle(signInModal);
                                  });
                      }
                    }),
                  "Enabled"
                ];
        }), [
        projectId,
        state.userLoginStatus
      ]);
  var navigationContinueLaterControl = React.useMemo((function () {
          var match = state.userLoginStatus;
          if (match === "LoggedIn" && projectStatus === "Draft") {
            return [
                    "Save and Continue Later",
                    (function () {
                        dispatch("SaveDraftProjectAndContinueLater");
                      })
                  ];
          }
          
        }), [state.userLoginStatus]);
  var tmp;
  tmp = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
              Url.visit(Routes_Project.Colocation.$$new);
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        projectId !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(ProjectWizard.H1.make, {
                                        children: "Edit Project"
                                      }),
                                  JsxRuntime.jsx(ProjectWizard.H2.make, {
                                        children: "Add Colocation Configurations"
                                      })
                                ]
                              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(ProjectWizard.H1.make, {
                                              children: "Step 1: Configure Your Colocation RFP"
                                            }),
                                        className: css.tooltipAndHeadingWrapper
                                      }),
                                  JsxRuntime.jsx(ProjectWizard.H2.make, {
                                        children: "Select Your Space, Power, and Bandwidth Options"
                                      })
                                ]
                              }),
                        JsxRuntime.jsx("div", {
                              children: "Complete this easy 3-Step Colocation RFP form to get multiple quotes and proposals directly from colocation providers. Choose the pricing and service that works best for you.",
                              className: css.subHeading
                            }),
                        Belt_Array.mapWithIndex(configurations, (function (mainIndex, configuration) {
                                var currentSectionVisibilities = Belt_Array.getExn(configurationVisibilities, mainIndex);
                                var mainValidationResults = Belt_Array.get(state.validationResults, mainIndex);
                                var match = configurations.length;
                                var match$1 = currentSectionVisibilities.colocationConfiguration;
                                var tmp;
                                tmp = match$1 === "Shown" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_BasicInfo.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                mainValidationResults: mainValidationResults,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_RackTypesAndPowerDensities.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                mainValidationResults: mainValidationResults,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_InternetConnectivity.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options,
                                                sectionVisibility: currentSectionVisibilities.internetConnectivity,
                                                toggleVisibility: toggleVisibility
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_CrossConnect.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                mainValidationResults: mainValidationResults,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options,
                                                sectionVisibility: currentSectionVisibilities.crossConnect,
                                                toggleVisibility: toggleVisibility
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_CloudDirectConnect.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options,
                                                sectionVisibility: currentSectionVisibilities.cloudDirectConnect,
                                                toggleVisibility: toggleVisibility
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_CertificationRequirements.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options,
                                                sectionVisibility: currentSectionVisibilities.certificationRequirements,
                                                toggleVisibility: toggleVisibility,
                                                mobile: mobile
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_DatecenterFeatures.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                options: options,
                                                sectionVisibility: currentSectionVisibilities.datacenterFeatures,
                                                toggleVisibility: toggleVisibility,
                                                mobile: mobile
                                              }),
                                          JsxRuntime.jsx(CreateColocationRfpProjectConfiguration_AdditionalNotes.make, {
                                                generateId: generateId,
                                                mainIndex: mainIndex,
                                                configuration: configuration,
                                                updateConfiguration: updateConfiguration,
                                                sectionVisibility: currentSectionVisibilities.additionalNotes,
                                                toggleVisibility: toggleVisibility
                                              })
                                        ]
                                      }) : null;
                                return JsxRuntime.jsx(ProjectWizardPanel.make, {
                                            title: CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.title(configuration),
                                            visibility: currentSectionVisibilities.colocationConfiguration,
                                            toggleVisibility: (function () {
                                                toggleVisibility(mainIndex, "ColocationConfiguration");
                                              }),
                                            deleteControl: match !== 1 ? (function () {
                                                  var nextConfigurations = Belt_Array.keepWithIndex(configurations, (function (_value, index) {
                                                          return index !== mainIndex;
                                                        }));
                                                  setConfigurations(function (param) {
                                                        return nextConfigurations;
                                                      });
                                                  saveDraft$1([
                                                        projectId,
                                                        nextConfigurations
                                                      ]);
                                                }) : undefined,
                                            children: tmp
                                          }, configuration.key);
                              })),
                        JsxRuntime.jsx(Button.make, {
                              size: "SM",
                              color: "Teal",
                              buttonClassName: css.addColocationSectionBtn,
                              onClick: (function (param) {
                                  setConfigurations(function (param) {
                                        return Belt_Array.concat(configurations, [CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.empty()]);
                                      });
                                  setConfigurationVisibilities(function (param) {
                                        return Belt_Array.concat(configurationVisibilities, [$$default]);
                                      });
                                }),
                              children: "+ Add One More Colocation Configuration"
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 1 of 3",
                      errorMessage: state.errorMessage,
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: navigationNextControl,
                        previous: navigationPreviousControl,
                        continueLater: navigationContinueLaterControl
                      },
                      mobile: mobile
                    }),
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                tmp
              ]
            });
}

var make = CreateColocationRfpProjectConfiguration;

export {
  css ,
  VisibilityState ,
  saveDraft ,
  Handlers ,
  make ,
}
/* css Not a pure module */
